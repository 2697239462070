<template>
  <div>
    <div class="header-images">
      <div class="cirkel">
        <div class="text-block-33">1</div>
      </div>
      <div class="cirkel grey">
        <div class="text-block-33">2</div>
      </div>
    </div>
  </div>
</template>
