import { isValidNumber } from 'libphonenumber-js'

export default {
    isValidImage(image) {
        return image != null && image != "" ? true : false
    },

    isValidEmail(email) {
        // split email by '@' symbol
        const parts = email.split("@")

        // email should have exactly two parts
        if (parts.length !== 2) {
            return false
        }

        const [username, domain] = parts

        // username should not be empty
        if (username.length === 0) {
            return false
        }

        // domain should have at least one period
        if (domain.indexOf(".") === -1) {
            return false
        }

        // domain should not start or end with a period
        if (domain.startsWith(".") || domain.endsWith(".")) {
            return false
        }

        return true
    },

    isValidPassword(password) {
        if (password == null) {
            return ["length", "digit", "lower", "upper"]
        }
        const array = []

        if (password.length < 7) {
            array.push("length")
        }
        if (!/\d/.test(password)) {
            array.push("digit")
        }
        if (!/[a-z]/.test(password)) {
            array.push("lower")
        }
        if (!/[A-Z]/.test(password)) {
            array.push("upper")
        }

        return array
    },

    isPasswordBoolean(password) {
        if (password == null) {
            return false
        }

        if (password.length < 7) {
            return false
        }
        if (!/\d/.test(password)) {
            return false
        }
        if (!/[a-z]/.test(password)) {
            return false
        }
        if (!/[A-Z]/.test(password)) {
            return false
        }

        return true
    },

    isValidURL(url) {
        var pattern = new RegExp(
            "^(https?:\\/\\/)?" + // protocol
            "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
            "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
            "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
            "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
            "(\\#[-a-z\\d_]*)?$",
            "i"
        ) // fragment locator
        return !!pattern.test(url)
    },

    isValidPhoneNumber(phonenumber) {

        if (isValidNumber(phonenumber)) {
            return true
        } else {
            return false
        }
    },

    isNotEmpty(inputStr) {
        if (inputStr == null) {
            return false
        }
        if (inputStr == "") {
            return false
        }
        return true
    },

    isStringInput(inputStr) {
        if (inputStr == null) {
            return false
        }
        const string = inputStr.replace(/ /g, "").toString()
        // Loop through each character in the input string
        for (let i = 0; i < string.length; i++) {
            // Check if the character is not alphabetic
            if (!isAlphabetic(string.charAt(i))) {
                return false
            }
        }
        // If all characters are alphabetic, return true
        return true
    },

    isValidTextArea(text) {
        if (text.replace(/ /g, "").length) {
            return true
        } else {
            return false
        }
    },
}

function isAlphabetic(char) {
    // Check if the character is within the range of lowercase or uppercase alphabetic characters in ASCII
    return (char >= "a" && char <= "z") || (char >= "A" && char <= "Z")
}
