<template>
  <div
    class="ImageDrop"
    @drop="handleDrop"
    @dragover.prevent
    @dragenter.prevent
    @mouseover="hover = true"
    @mouseleave="hover = false"
    @click="openFileExplorer"
  >
    <div
      v-if="imageSrc"
      class="image-container"
      :style="{
        backgroundImage: `url(${imageSrc})`,
        backgroundPosition: '50% 50%',
        backgroundSize: 'cover',
      }"
    >
 
    </div>
    <input type="file" ref="fileInput" @change="handleFileInput" class="hidden" />

    <div  >
      <div class="subheader-div middle margin-top" :style="imageSrc ? 'background-color:transparent;' : ''">
        <img src="/images/icons8-compact-camera-50.png" loading="lazy" class="camera" :style="imageSrc ? 'filter:invert(1)' : ''"/>
   
        <div v-if="hover" class="paragraph" :class="!imageSrc ? ' black' : ''">Drop image here</div>
        <div v-else class="paragraph" :class="!imageSrc ? ' black' : ''">Upload new photo</div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      imageSrc: null,
      hover: false,
      hasImage: false,
      placeholder: "Upload photo",
    }
  },
  methods: {
    handleDrop(event) {
      event.preventDefault()
      const file = event.dataTransfer.files[0]
      this.handleFile(file)
    },
    handleFileInput() {
      const file = this.$refs.fileInput.files[0]
      this.handleFile(file)
    },
    handleFile(file) {
      const reader = new FileReader()
      reader.onload = (e) => {
    
        this.imageSrc = e.target.result
          this.hasImage = true
      }
      reader.readAsDataURL(file)
      this.$emit("returnFile", file)
    },
    openFileExplorer() {
      this.$refs.fileInput.click()
    },
  },
}
</script>

<style scoped>
.subheader-div.middle.margin-top {
  margin-top: 0%;
  margin-bottom: 0%;
}
.ImageDrop {
  margin-bottom: 3%;
  border: 1px solid transparent;
  width: 200px;
  height: 200px;
  position: relative;
  margin-bottom: 3%;
  border-radius: 100%;
  overflow: hidden;
}

.hidden {
  display: none;
}

.subheader-div.middle.margin-top {
  background-color:rgb(197, 197, 197);
  background-image:none;
}

.image-container {
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position:absolute; 
  top:0;
  bottom:0;
  left:0;
  right:0;
}

.ImageDrop img.full-size {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.paragraph.black {
  color:black
}


</style>
