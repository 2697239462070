<template>
  <div class="small-er">
    <lottie-animation path="logo_animation.json" />
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"

export default {
  components: {
    LottieAnimation,
  },

  created() {},
}
</script>

<style scoped>
.small-er {
  padding: 20%;
  height: 100vh;
}
</style>
