<template>
  <router-view></router-view>
</template>

<script>
// import db_auth from "@/db/controller/auth"

export default {
  created() {
    // db_auth.logout()
  },
}
</script>

<style>
.background.mozo._100 {
  height: auto;
}

.white-background2 {
  width: 100%;
}

.subheader-div {
  height: 200px;
}
</style>
