<template>
  <div v-if="error == ''" class="inputfield-div">
    <h1 class="label">{{ label }} <span v-if="required" style="color: red">*</span></h1>
    <input
      autocomplete="false"
      ref="field"
      :placeholder="placeholder"
      :value="input_value"
      :type="type"
      class="inputfield"
      @keyup="setfield"
    />
  </div>
  <div v-else class="inputfield-div">
    <h1 class="label red">
      {{ label }} <span v-if="required" style="color: red">*</span>
    </h1>
    <div class="inputfield red-borders">
      <input
        autocomplete="false"
        ref="field"
        :placeholder="placeholder"
        :value="input_value"
        :type="type"
        class="inputfield-error"
        @keyup="setfield"
      />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["label", "type", "input_value", "placeholder", "id", "error", "required"],
  data: () => {
    return {
      value: "",
      state: true,
    }
  },
  created() {},
  methods: {
    setfield() {
      this.$emit("returnfield", this.$refs.field.value, this.id)
    },
  },
}
</script>

<style scoped>
.inputfield {
  outline: 0px;
  color: black;
  padding-left: 10px;
  padding-right: 5px;
  outline: none;
}
.inputfield-error {
  border: none;
  outline: none;
}
.full.width {
  width: 100%;
}
</style>
