<template>
  <div class="requirement">
    <template v-if="isPasswordCheckSucces()">
      <img src="/images/checkmark_1checkmark.png" loading="lazy" class="image-101" />
    </template>
    <template v-else>
      <img src="/images/close-01.svg" loading="lazy" class="image-101" />
    </template>
    <div class="paragraph black-text">{{ title }}</div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ["title", "id", "errors"],
  data: () => {
    return {
      state: false,
    }
  },
  created() {},
  methods: {
    isPasswordCheckSucces() {
      // console.log(this.id)
      return !this.errors.includes(this.id)
    },
  },

  computed: {},
}
</script>
