import { auth } from "@/db/firebase/index"

export default {
    async userState() {
        return new Promise((resolve, reject) => {
            const unsubscribe = auth.onAuthStateChanged((user) => {
                unsubscribe()
                resolve(user)
            }, reject)
        })
    },
    async signIn(email, password) {
        return await auth
            .signInWithEmailAndPassword(email, password)
            .then((user) => {
                return user.user
            })
            .catch((err) => {
                let errorCode = err.code

                if (errorCode === "auth/invalid-email") {
                    return "Sorry you have used the wrong username or password"
                } else if (errorCode === "auth/user-disabled") {
                    return "User account has been disabled"
                } else if (errorCode == "auth/too-many-requests") {
                    return "Sorry you have too many login attempts try it again in 10 minutes."
                } else if (errorCode === "auth/user-not-found") {
                    return "Sorry you have used the wrong username or password"
                } else if (errorCode === "auth/wrong-password") {
                    return "Sorry you have used the wrong username or password"
                } else {
                    return "Sorry you have used the wrong username or password"
                }
            })
    },
    async logout() {
        await auth
            .signOut()
            .then(async () => {
                return null
            })
            .catch(() => {
                throw "Not succesfully logout!"
            })
    },

    async setAuth() {
        await db_auth.logout()
        const user = await db_auth.userState().then((user) => {
            return user
        })

        if (user != null && user.uid == store.state.b2b.uid) {
            store.state.environment.isConfig = true
        }
    },

    async emailExists(email) {

        return await auth.fetchSignInMethodsForEmail(email).then(function (signInMethods) {
            if (signInMethods.length > 0) {
                return true;
            } else {
                return false;
            }
        }).catch(function (err) {
            return false;
        });
    }
}
