import * as Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import axios from "axios";
import VueAxios from "vue-axios";


const app = Vue.createApp(App)
app.use(VueAxios, axios)

app.use(store).use(router).mount('#app')
