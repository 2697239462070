<template>
  <div class="footer">
    <div class="logo-header nd-footer">
      <div class="logo-div" style="color:white; font-weight:700">
        POWERED BY MOZO
        <!-- <img :src="logo" loading="lazy" class="logo-img small" /> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data: () => {
    return {
      logo: "/images/mozologo.svg",
    }
  },
  methods: {},
}
</script>

<style scoped>
@media screen and (max-width: 767px) {
  .slogan-text {
    display: block;
    margin-left: 10px;
  }
}

.footer {
  padding-right: 5%;
  padding-left: 0%;
  width: 100%;
}

.logo-img.small {
  width: 65px;
}
</style>
