<template>
  <div class="div-block-6" style="width:100%; max-width:680px;">
    <div class="header-column-new">
      <div class="logo-header">
        <template v-if="isHomeRoute == null">
           <img src="/images/left-arrow.svg" @click="showAllApps()" style="height:60px;width:60px;cursor:pointer;filter:invert(1);"  />
         </template>
      </div>
      <div class="center">
        <div class="logo-div">
          <div class="logo-img" :style="'background-image: url(\'' + logo + '\');background-position: 50% 50%;background-size: cover;'" ></div>
        </div>
      </div>
      <div class="header-div">
       
      </div>
    </div>
  </div>
</template>


<script>
export default {

  props: [
    "isHomeRoute"
  ],

  data: () => {
    return {
      logo: "/images/mozo_logo_nopadding-01.svg",
    }
  },
  created() {
    if (this.inputLogo != null) {
      this.logo = this.inputLogo
    }
  },
  methods: {
    showAllApps() {
      window.location.href = this.backlink
    },
  },
  computed: {
    inputLogo: function () {
      return this.$store.state.b2b.logo
    },
    backlink: function () {
      return this.$store.state.b2b.backlink
    },
  },
}
</script>


<style scoped>
.logo-img {
  height: 60px;
  width:60px;
  max-height: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  border-style: solid;
  border-color: #f4f4f4;
  border-width: 2px;
}

.small-img {
  width: 90px;
  height: 90px;
  border: 1px solid black;
}

.header-column-new {
  background-image: none;

  display: grid;
  display: -ms-grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0% 5%;
  width: 100%;
}

.div-block-6 {
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-12 {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.header-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}


</style>
