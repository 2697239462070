import { auth, db2 } from "@/db/firebase/index.js"

export default {

    async createUser(email, password) {
        return await auth
            .createUserWithEmailAndPassword(email, password)
            .then((userCredential) => {
                return userCredential.user.uid;
            })
            .catch((err) => {
                return false
            });

    },

    async setUserInfo(b2c_uid, data) {
        await db2
            .collection("b2c")
            .doc(b2c_uid)
            .set(data)
            .catch((err) => {
                return false
            });
    },

    async addUserToCollection(b2b_uid, b2c_uid) {
        await db2
            .collection("users")
            .doc(b2b_uid)
            .collection("b2c")
            .doc(b2c_uid)
            .set({
                created_at: Date.now(),
            })
            .catch((err) => {
                return false
            });
    }

}