<template>
  <div class="_650px">
    <div class="heading-1">{{ title }}</div>
    <div class="paragraph">{{ text }}</div>
  </div>
</template>

<script>
export default {
  props: ["title", "text"],
}
</script>

<style scoped></style>
