<template>
  <div v-if="isLoaded">
    <div class="background mozo _100" :style="setBackground(color[0], color[1])">
      <Header :inputlogo="logo" />
      <div class="white-background2">
        <div class="default-width">
          <h1 class="heading-1 black middle">Create new invitations!</h1>
          <Inputfield
            :id="'email'"
            :placeholder="'Email'"
            :label="'Email'"
            :error="''"
            @returnfield="setField"
          />

          <GreenButton :title="'Send'" @isClicked="submit" />

          <p style="color: red">{{ error }}</p>
          <div style="margin-top: 10%"></div>
          <div style="overflow: auto; max-height: 60vh; width: 100%; padding: 5%">
            <div v-for="(account, index) in accounts" :key="index" style="width: 100%">
              <PendingBlock :email="account.email" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SubHeaderPicture from "@/components/B2C/SubHeaderPicture.vue"
import Header from "@/components/General/Header.vue"
import Subheadertext from "@/components/General/Subheadertext.vue"
import Personalurl from "@/components/General/personalurl.vue"
import PageCount from "@/components/General/PageCount.vue"
import Inputfield from "@/components/General/Inputfield.vue"
import Password from "@/components/General/Password.vue"
import GreenButton from "@/components/General/GreenButton.vue"
import TextArea from "@/components/General/TextArea.vue"

import onboarding_db from "@/db/controller/onboarding_db"
import mozoapp_db from "@/db/controller/mozoapp_db"
import db_auth from "@/db/controller/auth"

import PendingBlock from "@/components/AddEmail/PendingBlock.vue"

import methods from "@/helperMethods/general"

export default {
  components: {
    SubHeaderPicture,
    Header,
    Subheadertext,
    Personalurl,
    PageCount,
    Inputfield,
    Password,
    GreenButton,
    TextArea,
    PendingBlock,
  },

  data: () => {
    return {
      b2b: "adamandco",
      uid: null,
      data: {
        email: null,
      },
      accounts: [],
      color: [],

      admins: [],
      isLoaded: false,
      error: "",
    }
  },
  async created() {
    const response = await onboarding_db.getInfo(this.b2b)

    this.logo = response.logo
    this.admins = response.admins
    this.color = response.color

    this.backlink = "https://themozo.app/" + this.b2b

    const user = await db_auth.userState().then((user) => {
      return user
    })

    if (user == null) {
      this.$router.push("/login")
    } else if (!this.admins.includes(user.uid)) {
      this.$router.push("/login")
    }
    this.uid = user.uid

    this.isLoaded = true
    // this.accounts = await onboarding_db.getPendingResults(this.b2b)
  },
  methods: {
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
    setField(value, id) {
      if (id == "password") {
        this.password = value
      }
      this.data[id] = value
    },

    async submit() {
      if (await this.allDataChecked()) {
        this.error = ""
        const email = this.data.email.toLowerCase().replace(/\s/g, '')



        const doesExist = await db_auth.emailExists(email)
        if (doesExist) {
          this.error = "Sorry! Emailadres already exists!"
          return false
        }
        const doc_uid = await onboarding_db.addUser(this.uid, this.b2b, email)

        await this.sendEmail(doc_uid)

        this.accounts.push({ email: email })
      }
    },
    allDataChecked() {
      return true
    },

    async sendEmail(code) {
      var response = await this.axios.post(
        "https://api.themozo.app/general/email/sendEmail",
        {
          auth_token: "12345",
          email: this.data.email.toLowerCase(),
          title: "Activate the new A'DAM&Co. app",
          message:
            "Dear member, <br> This is your personal registration link to register for our new A'DAM&Co. App: " +
            "<a href='https:\/\/register.themozo.app\/b2c?code=" +
            code +
            "'>" +
            "Link" +
            "</a>" +
            "</b><br><br>" +
            "If there are any questions, email us at adam@adamandco.nl" +
            "<br><br>Love,<br>A'DAM&Co. & Team Mozo",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )

      console.log(response)
    },
  },
  computed: {
    logo: {
      get() {
        return this.$store.state.b2b.logo
      },
      set(value) {
        this.$store.state.b2b.logo = value
      },
    },
    backlink: {
      get() {
        return this.$store.state.b2b.backlink
      },
      set(value) {
        this.$store.state.b2b.backlink = value
      },
    },
  },
}
</script>
