import "firebase/compat/firestore";
import "firebase/compat/storage";
import "firebase/compat/auth";
import firebase from "firebase/compat/app";


// Config
import config_onboarding from "./config-onboarding.js";
import config_mozoapp from "./config-mozoapp.js";

// Initializing firebase app
const app2 = firebase.initializeApp(config_mozoapp);
const storage = firebase.storage()


const app1 = firebase.initializeApp(config_onboarding, 'second');


const db1 = app1.firestore();
const db2 = app2.firestore();

const auth = app2.auth();



export { db1, db2, auth, storage };
