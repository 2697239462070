<template>
  <div @click="isClicked" class="button">
    <h1 class="paragraph">{{ title }}</h1>
  </div>
</template>

<script>
export default {
  props: ["title"],
  methods: {
    isClicked() {
      this.$emit("isClicked", true)
    },
  },
}
</script>
