<template>
  <button @click="callMethod">send all emails</button>
</template>

<script>
import onboarding_db from "@/db/controller/onboarding_db";
// const jsonData = require("/Users/gijsdeleeuw/Documents/MOZO/Onboarding/emailexport.json");

export default {
  async created() {
    this.emails = (await this.getEmails())

    console.log(this.emails[0])
  },
  methods: {
    getEmails() {
      // const data = require("/Users/gijsdeleeuw/Documents/MOZO/Onboarding/emailexport.json");
      const collection = data["collection:emailadresses"];
      const transformedArray = Object.keys(collection).map((key) => ({
        email: collection[key].email,
        id: key,
      }));

      return transformedArray;
    },
    async callMethod() {


      const emails = this.emails

      for (let i = 0; i < emails.length; i++) {
        try {
          const response = await this.axios.post(
            "https://api.themozo.app/general/email/sendEmail",
            {
              auth_token: "12345",
              email: emails[i].email,
              title: "Activate the new A'DAM&Co. app",
              message:
                "Dear member, <br> This is your personal registration link to register for our new A'DAM&Co. App: " +
                "<a href='https://register.themozo.app/b2c?code=" +
                emails[i].id +
                "'>" +
                "personal link" +
                "</a><br>" +
                "This is a short tutorial too save the webapp in your homescreen: <a href='https://youtube.com/shorts/JQS6o7nAHnM?feature=share'>instructions</a>" +
                "</b><br><br>" +
                "If there are any questions, email us at adam@adamandco.nl" +
                "<br><br>Love,<br>A'DAM&Co. & Team Mozo",
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log(response.data);
        } catch (error) { 
          console.error(error);
        }
      }
    },
  },
};
</script>
