<template>
  <div v-if="error == ''" class="inputfield-div">
    <h1 class="label">{{ label }} <span v-if="required" style="color: red">*</span></h1>
    <textarea
      ref="field"
      :placeholder="placeholder"
      :value="input_value"
      maxlength="100"
      class="inputfield"
      @keyup="setfield"
    ></textarea>
  </div>
  <div v-else class="inputfield-div">
    <h1 class="label red">
      {{ label }} <span v-if="required" style="color: red">*</span>
    </h1>
    <div class="inputfield red-borders">
      <textarea
        ref="field"
        :placeholder="placeholder"
        maxlength="100"
        :value="input_value"
        class="inputfield-error"
        @keyup="setfield"
      ></textarea>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  props: ["label", "input_value", "placeholder", "id", "error", "required"],
  data: () => {
    return {
      value: "",
      state: true,
    }
  },
  created() {},
  methods: {
    setfield() {
      this.$emit("returnfield", this.$refs.field.value, this.id)
    },
  },
}
</script>
<style scoped>
.inputfield {
  outline: 0px;
  color: black;
  padding-left: 10px;
  padding-right: 5px;
  outline: none;
  height: 100px;
}
.inputfield-error {
  border: none;
  outline: none;
}
.full.width {
  width: 100%;
}

textarea {
  resize: none;
  height: 100%;
  width: 100%;
}
</style>
