import { db1 } from "@/db/firebase/index.js"
import methods from "@/helperMethods/general"

export default {
    async getPendingResults(b2b) {
        const accounts = await db1
            .collection("b2b")
            .doc(b2b)
            .collection("b2c")
            .where("active", "==", false)
            .get()
            .then((resp) => {
                return resp.docs
            })
            .catch((err) => {
                throw err.message
            })
        const array = []
        for (var i = 0; i < accounts.length; i++) {
            let b2c = accounts[i].id
            array.push(await this.getUser(b2b, b2c))
        }

        return array
    },
    async getAllUsers(b2b) {
        try {
            const snapshot = await db1
                .collection("b2b")
                .doc(b2b)
                .collection("emailadresses")
                .get();
    
            let emails = [];
            snapshot.forEach(doc => {
                let user = doc.data();
                if(user) {
                    emails.push({ ...user, id: doc.id});
                }
            });
    
            return emails;
        } catch (error) {
            throw error.message;
        }
    },
    async setUserActive(b2b, b2c) {
        await db1
            .collection("b2b")
            .doc(b2b)
            .collection("b2c")
            .doc(b2c)
            .set({
                active: true,
                created_at: Date.now(),
            })
            .catch(() => {
                return false
            })

        return true
    },

    async addUser(b2b_uid, b2b, email) {
        const doc_id = await methods.randomGenerator(20)

        await db1
            .collection("b2b")
            .doc(b2b)
            .collection("emailadresses")
            .doc(doc_id)
            .set({
                activationcode: doc_id,
                changeCode: true,
                email: email,
                uid: b2b_uid,
            })
            .catch(() => {
                return false
            })

        await db1
            .collection("b2b")
            .doc(b2b)
            .collection("b2c")
            .doc(doc_id)
            .set({
                active: false,
                created_at: null,
            })
            .catch(() => {
                return false
            })

        return doc_id
    },

    async getInfo(b2b) {
        return await db1
            .collection("b2b")
            .doc(b2b)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let data = doc.data()
                    data.b2b = b2b
                    return data
                }
            })
            .catch((err) => {
                return null
            })
    },

    async getUser(b2b, b2c) {
        const response1 = await db1
            .collection("b2b")
            .doc(b2b)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let data = doc.data()
                    data.activationcode = b2c
                    return data
                }
            })
            .catch((err) => {
                return null
            })
        const response2 = await db1
            .collection("b2b")
            .doc(b2b)
            .collection("b2c")
            .doc(b2c)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    return doc.data()
                }
            })

        const response3 = await db1
            .collection("b2b")
            .doc(b2b)
            .collection("emailadresses")
            .doc(b2c)
            .get()
            .then((doc) => {
                if (doc.exists) {
                    let data = doc.data()
                    return data
                }
            })
            .catch((err) => {
                return null
            })

        return { ...response1, ...response2, ...response3 }
    },

    async checkEmail(b2b, email) {
        return await db1
            .collection("b2b")
            .doc(b2b)
            .collection("emailadresses")
            .where("email", "==", email)
            .limit(1)
            .get()
            .then((resp) => {
                return resp.docs[0]
            })
            .catch((err) => {
                return null
            })
    },

    async getEmail(b2b, email) {
        const response = await this.checkEmail(b2b, email)

        if (response.exists) {
            return await db1
                .collection("b2b")
                .doc(b2b)
                .collection("emailadresses")
                .doc(response.id)
                .get()
                .then((doc) => {
                    if (doc.exists) {
                        return doc.data()
                    }
                })
        }

        return accounts
    },
}
