<template>
  <div class="background mozo" style="background-image: repeating-radial-gradient(circle farthest-corner at 100% 0%, #000, #000);
    padding: 5%;">

    <div class="page-body-center">
      <div class="form-div width-cap">
        <h1 class="heading1 bold">Welcome again</h1>
        <p class="paragraph">Login to your account</p>
        <div class="form-block w-form">
          <form>
            <input
              @keyup.enter="login"
              ref="email"
              placeholder="Email"
              type="text"
              class="text-field marge-between w-input"
              maxlength="30"
            />
            <input
              @keyup.enter="login"
              ref="password"
              placeholder="Password"
              type="password"
              class="text-field marge-between w-input"
              maxlength="30"
            />
            <div class="div-block">
              <div class="columns w-row">
                <div class="w-col w-col-6"></div>
                <div class="w-col w-col-6">
                  <div class="rightalign-div">
                    <a href="https://themozo.app/reset-password" class="paragraph white"
                      >Forgot password?</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div>
          <a style="background-color:#D11616;border:none;" @click="login" class="submit-button w-button">Login</a>
        </div>

        <template v-if="error !== ''">
          <div>
            <div id="error-message" class="error-message">{{ error }}</div>
          </div>
        </template>
      </div>
    </div>

    <LoginFooter />
  </div>
</template>

<script>
import db_auth from "@/db/controller/auth"

// in elements 2.0
import LoginFooter from "@/components/Login/LoginFooter.vue"
import Header from "@/components/General/Header.vue"

import onboarding_db from "@/db/controller/onboarding_db"

export default {
  components: {
    LoginFooter,
    Header,
  },
  data() {
    return {
      error: "",
    }
  },
  async created() {
    console.log(this.store)
    const user = await db_auth.userState().then((user) => {
      return user
    })

    if (user != null && response.admins.includes(user.uid)) {
      this.isConfig = true
      this.$router.push("/addemail")
    } else {
      this.isConfig = false
    }
  },

  methods: {
    async login() {
      var email = this.$refs.email.value
      var password = this.$refs.password.value
      const user = await db_auth.signIn(email, password)

      const response = await onboarding_db.getInfo("adamandco")

      if (user != null && response.admins.includes(user.uid)) {
        this.isConfig = true
        this.$router.push("/addemail")
      } else {
        this.isConfig = false
      }
    },
    setBackground() {
      if (this.store.b2b && this.store.b2b.color[0] && this.store.b2b.color[1]) {
        return methods.setBackground(this.store.b2b.color[0], this.store.b2b.color[1])
      } else {
        return ""
      }
    },
  },
  computed: {
    store: function () {
      return this.$store.state
    },
    isConfig: {
      get() {
        return this.store.environment.isConfig
      },
      set(value) {
        this.store.environment.isConfig = value
      },
    },
  },
}
</script>

<style scoped>
.page-body-center {
  display: flex;
  justify-content: center;
  align-content: center;
  height: 70vh;
  width: 100%;
}

.form-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  width: 100%;
  max-width: 650px;
}
.rightalign-div {
  text-align: right;
  padding: 5% 0%;
}
</style>
