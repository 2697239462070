import register_b2c from "./mozoapp_db/register_b2b"
import image from "./mozoapp_db/image"
import onboarding_db from "./onboarding_db"

export default {
    async registerUserB2C(b2b, b2b_uid, data, password, file) {
        const b2c_uid = await register_b2c.createUser(data.email, password)

        if (b2c_uid) {
            const image_url = await image.add(b2b_uid, "b2c_users", b2c_uid, file)

            if (image_url) {
                data["image"] = image_url

                let result = true;
                result = await register_b2c.setUserInfo(b2c_uid, data)
                result = await register_b2c.addUserToCollection(b2b_uid, b2c_uid)
                if(result == false) {
                    return false;
                }

                const user = await onboarding_db.getUser(b2b, data.activationcode)
                if(user==null) {
                    return false;
                } else if (user.changeCode) {
                    return await onboarding_db.setUserActive(b2b, data.activationcode)
                }
                return true;

            } else {
                return false;
            }
        } else {
            return false
        }
    },
}
