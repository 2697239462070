<template>
  <div class="background mozo _100 center" style="background-color:black">
    <Header :isHomeRoute="true" />
    <div class="front-layer top-screen-desktop">
      <Subheadertext
        :title="'Welcome'"
        :text="'Ervaar een geweldige gast beleving met Mozo. Waarschijnlijk ben je op deze pagina gekomen omdat je linkje niet klopte. Geen zorgen! Probeer het nog een keer of vraag even na bij degene die je de link gestuurd heeft wat er fout is gegaan.'"
      />
    </div>
  </div>
</template>

<script>
import Header from "@/components/General/Header.vue"
import Subheadertext from "@/components/General/Subheadertext.vue"
export default {
  components: {
    Header,
    Subheadertext,
  },
}
</script>

<style scoped>
.background.mozo._100.center {
  justify-content: flex-start;
  align-items: center;
}
</style>
