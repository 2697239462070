<template>
  <div class="pending-grid">
    <div class="column-pending">
      <p>{{ email }}</p>
    </div>
    <div class="column-pending">
      <p class="italic-text">Pending</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["email"],
}
</script>

<style scoped>
.pending-grid {
  width: 100%;
  height: 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-bottom: 5%;
  border-radius: 28px;
  padding: 5%;
}

.italic-text {
  font-style: italic;
}

.column-pending {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5%;
}
</style>
