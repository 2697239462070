import { createStore } from 'vuex'
import environment from "@/store/data/environment.js"
import b2b from "@/store/data/b2b.js"

export default createStore({
    state: {
        environment,
        b2b
    }
})
