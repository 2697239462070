<template>
  <div>
    <div
      v-if="isLoaded"
      class="background mozo _100"
      :style="setBackground(color[0], color[1])"
    >
      <Header />
      <div class="white-background2">
        <div class="default-width">
          <h1 class="heading-1 black middle">Create your account</h1>
          <SubHeaderPicture @returnFile="setImageFile" />
          <Inputfield
            :id="'email'"
            :placeholder="'Email'"
            :label="'Email'"
            :error="error.email"
            :required="true"
            :input_value="data.email"
            @returnfield="setField"
          />
          <Password :error="error.password" @returnfield="setField" />

          <Inputfield
            :id="'firstname'"
            :label="'First name'"
            :placeholder="'First name'"
            :error="error.firstname"
            :required="true"
            :input_value="data.firstname"
            @returnfield="setField"
          />
          <Inputfield
            :id="'lastname'"
            :label="'Last name'"
            :placeholder="'Last name'"
            :error="error.lastname"
            :required="true"
            :input_value="data.lastname"
            @returnfield="setField"
          />
          <Inputfield
            :id="'company'"
            :label="'Company name'"
            :placeholder="'Company name'"
            :error="error.company"
            :required="true"
            :input_value="data.company"
            @returnfield="setField"
          />
          <Inputfield
            :id="'phone_number'"
            :label="'Phone number (use your country code, e.g. +31)'"
            :placeholder="'Phone number'"
            :error="error.phone"
            :required="true"
            :input_value="data.phone_number"
            @returnfield="setField"
          />

          <Inputfield
            :id="'website'"
            :label="'Website'"
            :placeholder="'Website, Instagram or Linkedin'"
            :error="error.website"
            :required="true"
            :input_value="data.website"
            @returnfield="setField"
          />
          <Inputfield
            :id="'linkedin'"
            :label="'LinkedIn'"
            :placeholder="'voeg URL toe'"
            :error="error.linkedin"
            :input_value="data.linkedin"
            @returnfield="setField"
          />
          <Inputfield
            :id="'instagram'"
            :label="'Instagram'"
            :placeholder="'voeg URL toe'"
            :error="error.instagram"
            :input_value="data.instagram"
            @returnfield="setField"
          />

          <TextArea
            :id="'bio'"
            :label="'Bio'"
            :placeholder="'maximum 100 characters'"
            :error="error.bio"
            :required="true"
            :input_value="data.bio"
            @returnfield="setField"
          />

          <template v-if="error !== ''">
            <div>
              <div id="error-message" class="error-message">
                {{ this.error.general_message }}
              </div>
            </div>
          </template>

          <GreenButton :title="'Register'" @isClicked="submit" />
        </div>
      </div>
      <Footer />
    </div>
    <div v-else>
      <Loading />
    </div>
  </div>
</template>

<script>
import Loading from "@/components/General/Loading.vue"
import SubHeaderPicture from "@/components/B2C/SubHeaderPicture.vue"
import Header from "@/components/General/Header.vue"
import Subheadertext from "@/components/General/Subheadertext.vue"
import Personalurl from "@/components/General/personalurl.vue"
import PageCount from "@/components/General/PageCount.vue"
import Inputfield from "@/components/General/Inputfield.vue"
import Password from "@/components/General/Password.vue"
import GreenButton from "@/components/General/GreenButton.vue"
import TextArea from "@/components/General/TextArea.vue"

import onboarding_db from "@/db/controller/onboarding_db"
import mozoapp_db from "@/db/controller/mozoapp_db"

import methods from "@/helperMethods/general"

import validation from "@/helperMethods/validationMethods"

import Footer from "@/components/General/Footer.vue"
import validationMethods from "@/helperMethods/validationMethods"

export default {
  components: {
    SubHeaderPicture,
    Header,
    Subheadertext,
    Personalurl,
    PageCount,
    Inputfield,
    Password,
    GreenButton,
    TextArea,
    Footer,
    Loading,
  },

  data: () => {
    return {
      b2b: "adamandco",
      image: {
        file: null,
        url: null,
      },
      password: null,
      uid: null,
      data: {
        activationcode: null,
        image: null,
        email: null,
        firstname: null,
        lastname: null,
        company: null,
        phone_number: null,
        email: null,
        linkedin: null,
        instagram: null,
        bio: null,
        website: null,
      },
      color: [],
      isLoaded: false,
      error: {
        image: "",
        email: "",
        password: "",
        firstname: "",
        lastname: "",
        company: "",
        phone: "",
        website: "",
        linkedin: "",
        instagram: "",
        bio: "",
        general_message: "",
      },
    }
  },
  async created() {
    const response = await onboarding_db.getUser(this.b2b, await this.getUrlCode("code"))
    this.color = response.color
    this.logo = response.logo

    this.backlink = "https://themozo.app/" + this.b2b

    if (response == null) {
      window.open("https://themozo.app/" + this.b2b, "_self")
    } else if (response.active) {
      window.open("https://themozo.app/" + this.b2b, "_self")
    }
    this.uid = response.uid

    this.data.activationcode = response.activationcode

    this.isLoaded = true
  },
  mounted() {},
  methods: {
    setImageFile(file) {
      this.image.file = file
    },
    setField(value, id) {
      if (id == "password") {
        this.password = value
      } else if (id == "email") {
        this.data[id] = value.toLowerCase()
      } else {
        this.data[id] = value
      }
    },
    getUrlCode(param) {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get(param)
    },
    async submit() {
      this.isLoaded = false
      if (await this.allDataChecked()) {
        const email_exists = await onboarding_db.checkEmail(this.b2b, this.data.email)
        if (email_exists) {
          const done = await mozoapp_db.registerUserB2C(
            this.b2b,
            this.uid,
            this.data,
            this.password,
            this.image.file
          )

          if (done) {
            window.open("https://themozo.app/" + this.b2b, "_self")
          } else {
            this.error.general_message =
              "Email Is Already in Use Or You Have Used A Wrong Emailadress"
            this.isLoaded = true
          }
        } else {
          this.error.general_message = "Email Doesn't Exists"
          this.isLoaded = true
        }
      } else {
        this.isLoaded = true
      }
    },
    setBackground(color1, color2) {
      return methods.setBackground(color1, color2)
    },
    async allDataChecked() {
      this.error.general_message = ""
      this.error = {
        image: "",
        email: "",
        password: "",
        firstname: "",
        lastname: "",
        company: "",
        phone: "",
        website: "",
        linkedin: "",
        instagram: "",
        bio: "",
        general_message: "",
      }

      if (!(await validation.isValidImage(this.image.file))) {
        this.error.image = "Whoops ! Please upload an image !"
        this.error.general_message = this.error.image
      }

      if (!(await validation.isValidEmail(this.data.email))) {
        this.error.email = "Whoops ! Email is not correct !"
        this.error.general_message = this.error.email
      }

      if (!(await validation.isPasswordBoolean(this.password))) {
        this.error.password = "Whoops ! Password is not correct !"
        this.error.general_message = this.error.password
      }
      if (!(await validation.isNotEmpty(this.data.firstname))) {
        this.error.firstname = "Whoops ! First name is not correct !"
        this.error.general_message = this.error.firstname
      }

      if (!(await validation.isNotEmpty(this.data.lastname))) {
        this.error.lastname = "Whoops ! Last name is not correct !"
        this.error.general_message = this.error.lastname
      }

      if (!(await validation.isNotEmpty(this.data.website))) {
        this.error.lastname = "Whoops ! Website is not correct !"
        this.error.general_message = this.error.website
      }

      if (!(await validation.isNotEmpty(this.data.company))) {
        this.error.company = "Whoops ! Company is not correct !"
        this.error.general_message = this.error.company
      }
      if (!(await validation.isValidPhoneNumber(this.data.phone_number))) {
        this.error.phone = "Whoops ! Phone number is not correct !"
        this.error.general_message = this.error.phone
      }

      if (!(await validation.isNotEmpty(this.data.bio))) {
        this.error.bio = "Whoops ! Bio is not correct !"
        this.error.general_message = this.error.bio
      }

      if (this.data.activationcode == null) {
        this.error.general_message = "Whoops ! Something went wrong !"
      }

      if (this.error.general_message == "") {
        return true
      }

      return false
    },
  },
  computed: {
    logo: {
      get() {
        return this.$store.state.b2b.logo
      },
      set(value) {
        this.$store.state.b2b.logo = value
      },
    },
    backlink: {
      get() {
        return this.$store.state.b2b.backlink
      },
      set(value) {
        this.$store.state.b2b.backlink = value
      },
    },
  },
}
</script>
