<template>
  <div class="requirement-div margin-top">
    <div v-for="(error, index) in password_checks" :key="index">
      <Requirement :title="error.title" :id="error.id" :errors="errors" />
    </div>
  </div>
</template>

<script>
import Requirement from "./Requirement.vue"

export default {
  components: {
    Requirement,
  },
  props: ["errors"],
  created() {},

  data: () => {
    return {
      password_checks: [
        { title: "At least 8 characters", id: "length" },
        { title: "Contains one lower character", id: "lower" },
        { title: "Contains one upper case character", id: "upper" },
        { title: "Contains one digit", id: "digit" },
      ],
    }
  },
  methods: {},
}
</script>

<style scoped>
.requirement-div.margin-top {
  margin-bottom: 5px;
}
</style>
