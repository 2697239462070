<template>
  <div class="inputfield-div">
    <h1 class="paragraph black-text">{{ Label }}</h1>
    <div>
      <div class="half-half-button">
        <div class="first-half">
          <h1 class="paragraph black-text small-text">https://themozo.app/</h1>
        </div>
        <div class="second-half">
          <input
            ref="field"
            :placeholder="placeholder"
            :value="name"
            class="full"
            @keyup="setfield"
            :type="type"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["Text", "type", "placeholder", "Label"],
  data: () => {
    return {
      name: "",
    }
  },
  created() {
    if (this.input_name != null) {
      this.name = this.input_name
    }
  },
  methods: {
    setfield() {
      this.$emit("returnfield", this.$refs.field.value, this.Label)
    },
  },
}
</script>

<style scoped>
.second-half {
  width: 50%;
  color: black;
}

.full {
  width: 100%;
  height: 100%;
}

input {
  outline: none;
  border-width: 0px;
}

.half-half-button {
  border-radius: 8px;
  overflow: hidden;
}
.inputfield-div {
  margin-top: 10px;
}
.paragraph.black-text {
  margin-bottom: 2px;
}
</style>
