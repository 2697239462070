import { createRouter, createWebHistory } from 'vue-router'
import B2C from '@/views/B2C.vue'
import Home from '@/views/Home.vue'
import AddEmail from "@/views/AddEmail.vue"
import Login from "@/views/Login.vue"

import sendAllEmails from "@/views/sendAllEmails.vue"

const routes = [
  {
    path: '/b2c',
    name: 'b2c',
    component: B2C
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/addemail',
    name: 'addemail',
    component: AddEmail
  },
  {
    path: '/sendAll',
    name: 'jjj',
    component: sendAllEmails
  },
  {
    path: '/',
    name: 'home',
    component: Home
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
