import { storage } from "@/db/firebase/index.js"

export default {
    async add(uid, folder, b2c_uid, file) {
        let url = ""

        const storageRef = storage.ref(uid + "/" + folder + "/" + b2c_uid)

        await storageRef.put(file).catch((err) => {
            throw err.message
        })
        await storageRef.getDownloadURL().then((x) => {
            url = x
        })
        return url
    },
}