<template>
  <div style="width: 100%" v-if="isLoaded">
    <Inputfield
      :id="'password1'"
      :placeholder="'Password'"
      :type="'password'"
      :label="'Password'"
      :error="error"
      :required="true"
      :input_value="password1"
      @returnfield="setField"
    />
    <Password :errors="errors" />
    <Inputfield
      :id="'password2'"
      :placeholder="'Password'"
      :type="'password'"
      :label="'Repeat Password'"
      :error="error"
      :required="true"
      :input_value="password2"
      @returnfield="setField"
    />
  </div>
</template>

<script>
import Inputfield from "@/components/General/Inputfield.vue"
import Password from "@/components/B2C/Password.vue"

import validation from "@/helperMethods/validationMethods"

export default {
  components: {
    Inputfield,
    Password,
  },
  props: ["error"],
  data: () => {
    return {
      password1: null,
      password2: null,

      errors: [],
      isLoaded: false,
    }
  },
  async created() {
    this.errors = await validation.isValidPassword(this.password1)

    this.isLoaded = true
  },
  methods: {
    async setField(value, id) {
      this[id] = value

      this.errors = await validation.isValidPassword(this.password1)

      if (this.errors.length == 0 && this.password1 == this.password2) {
        this.$emit("returnfield", this.password1, "password")
      } else {
        this.$emit("returnfield", null, "password")
      }
    },
  },
}
</script>
